.news {
  margin: 10px;
}
.item {
  margin: 0 10px 10px 10px;
}
.item:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
.date {
  width: 25%;
  float: left;
  text-align: left;
}
.desc {
  width: 70%;
  float: right;
  text-align: left;
}
