.container {
  width: 480px;
  padding: 10px 10px 0 10px;
  margin: 0 auto;
  margin-bottom: 5px;
}
.main {
  width: 100%;
  min-height: 330px;
  height: auto !important;
  border: thin solid #bb3300;
  text-align: left;
  border-radius: 20px;
  padding: 10px 0;
}
.header {
  width: 100%;
  height: 150px;
  text-align: left;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  background-image: url("./assets/images/logo.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}
.header ul {
  position: absolute;
  top: 120px;
  padding: 0;
}
.header ul li {
  display: inline-block;
  float: left;
}
.header ul li a {
  display: block;
  padding: 0 15px;
  outline: none;
  text-decoration: none;
  line-height: 20px;
}
.footer {
  font-size: 90%;
  width: 100%;
  clear: both;
  text-align: center;
  margin: 10px 0;
}
