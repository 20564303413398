.no_webgl {
  margin: 10px auto;
  text-align: center;
}
.back {
  margin-bottom: 10px;
  text-align: center;
}
.viewer {
  width: 100%;
  height: 400px;
}
