.empty,
.error {
  margin: 120px 0;
  text-align: center;
}
.error {
  color: red;
}
.result {
  margin: 10px 10px 20px 10px;
}
.title {
  font-size: 105%;
}
.remark {
  margin: 5px 5px 0 10px;
}
