.formGroup {
  margin: 0 10px;
  height: 40px;
}
.formGroup:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
.formGroup label {
  width: 55%;
  float: left;
}
.value {
  float: right;
  text-align: right;
}
.range {
  margin: 0 10px;
}
.txtParam {
  width: 50px;
}
.txtParamStr {
  width: 100px;
}
.txtKeyword {
  width: 150px;
}
.selParam {
  width: 150px;
}
.submit {
  text-align: center;
}
.submit input {
  padding: 5px 15px;
}
