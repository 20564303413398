.contact {
  margin: 20px;
}
.contact h4 {
  margin: 5px 0 10px 0;
}
.contact p {
  margin: 5px 0 15px 15px;
}
.notice {
  margin: 10px 0 20px;
  color: #ff0000;
}
.note {
  margin-left: 15px;
  font-style: italic;
}
