.help {
  margin: 10px;
}
.help p {
  margin: 5px 15px 5px 15px;
}
.index {
  margin: 0 0 16px 0;
  padding-left: 30px;
}
.index li {
  margin-bottom: 15px;
}
.search_links {
  margin: 0 10px;
  font-size: 75%;
  text-align: right;
}
.search_links:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
.search_links li {
  display: inline-block;
}
.search_links li a {
  display: block;
  padding: 0 10px;
  outline: none;
  text-decoration: none;
}
.title {
  font-weight: bold;
  font-size: 100%;
  margin: 5px;
}
.legend {
  font-weight: bold;
  margin-right: 5px;
}
.figure {
  width: 100%;
  margin: 15px 0;
  text-align: center;
}
.figure img {
  width: 320px;
}
