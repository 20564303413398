.detail {
  margin: 10px 10px 20px 10px;
}
.title,
.author,
.detail figure,
.detail dl {
  margin: 5px 0 10px 0;
}
.detail figure {
  text-align: center;
}
.detail figure img {
  width: 80%;
}
.detail dl dt {
  font-weight: bold;
}
.detail dl dd {
  margin-left: 30px;
}
